import React  from "react";
import {Text,Heading,Box,Flex, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import MeinSeo from '../components/seo'
import { motion } from "framer-motion";
export default function Mayer(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >  
        <MeinSeo title="Das Schicksal der jüdischen Familie Mayer aus Oberwesel" description="Bilder und Dokumente zum Schicksal der Familie Mayer aus Oberwesel" />
        
        <Box  mt="4px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid py="8" gap={4} columns={[1,2,2]}>
        <Box>
            <Heading variant="utitel">Rathausstraße 4 / Chablis-Straße 4
            <br/>
            Jakob und Clementine Mayer und ihre Töchter Mathilde, Erna und Herta,
            Walter und Mathilde (Tilly) Loeb

            </Heading>
            
            <Text variant="solid">
            In der Rathausstraße 4 wohnte von 1905 bis 1938 die Familie des Metzgers Jakob Mayer (*1879). Er ist ein Sohn Bernhard Mayers aus der Kirchstraße 25, ein Bruder von Emma Frenkel, geb. Mayer. Seine Frau Clementine ist die Schwester von Emmas Mann Willi Frenkel. Clementine und Jakob Mayer hatten drei Kinder: Mathilde, genannt Tilly (*1907), Erna (*1909) und Herta (*1910). Tilly heiratete 1934 Walter Loeb aus Enkirch, sie wohnten in der Simmernerstr. 4a (heute Chablis-Str. 4). Walter Loeb verlor 1937 seine Stellung als Vertreter, da die Firmen »arisiert« wurden. 1938 flohen sie nach Cuba und von dort in die USA. Tilly starb 1969 in New York. Erna floh 1936 nach Palästina. 1938 reiste sie über Le Havre nach New York und war als Kindermädchen tätig. Sie heiratete 1942 den Kölner Erich Koppel. Erna starb 1978 in New York. Herta war in Frankfurt bei den Gebr. Carlebach in Stellung. Sie floh 1938 nach New York und heiratete dort Isaak Kahn aus Könen.<br/>
            Jakob Mayer wurde 1938 die Erlaubnis zum Betreiben seiner Viehhandlung entzogen. Sie zogen zu Gersons in die Kirchstraße, und 1939 zu Mayers in die Liebfrauenstraße. Im März 1941 gelang ihnen mit der Hilfe von T. J. Oakley Rhinelander die Flucht. Jack Mayer starb dort 1960.

            </Text>
            <Heading variant="utitel">Im Februar 1936</Heading>
            <Text variant="solid">flieht Erna Mayer, geboren am 14.04.1909 in Oberwesel, nach Palästina; sie lebt in Tel Aviv. 1938 reist sie über Le Havre nach New York und lebt 1940 als Kindermädchen im Haushalt der Familie Tropp. Auch schlägt sie sich mit Schreibmaschinenarbeiten durch. Sie heiratet 1942 den aus Köln geflohenen Erich Koppel. Sie bekommen zwei Kinder. Erna stirbt am 22.11.1978 in New York, ihr Mann Eric dort am 13.05.1991.</Text>
            <Heading variant="utitel">1937/38</Heading>
            <Text variant="solid">Herta Mayer, geboren am 01.05.1910, ist nach verschiedenen Stationen in Frankfurt bei der Familie von Moritz Carlebach, Mitgesellschafter der Fa. Gebr. Carlebach, in Stellung. Im November 1938 wird Carlebach verhaftet und nach Buchenwald verschleppt. Sein Sohn Emil, im Widerstand aktiv und seit 1934 in Haft, trifft im November 1938 in Buchenwald seinen Vater wieder. Emil Carlebach ist an der Meuterei der Häftlinge am 4./5. April 1945 beteiligt. Er ist nach dem Krieg Mitbegründer der Frankfurter Rundschau.</Text>
            <Heading variant="utitel">1. Januar 1938</Heading>
            <Text variant="solid">Jakob Mayer, geboren am 20.04.1879 in Oberwesel, wird die Erlaubnis zum Betreiben seiner 1903 gegründeten Viehhandlung entzogen. Er eröffnet ein Tabakgeschäft, das er aber schon im November aufgeben muss.</Text>
            <Heading variant="utitel">25. Mai 1938</Heading>
            <Text variant="solid">Ernas Schwester Tilly, geboren am 31.10.1907, ist seit 1934 verheiratet mit Walter Meyer Loeb (*1903) aus Enkirch. Sie wohnen zur Miete in der Simmerner Str. 4a. Im Sommer 1937 verliert Walter Loeb seine Stellung als Vertreter, da die Firmen »arisiert« werden. Seine Mutter Jeanette Loeb wartet 1937 bei ihnen bis zur Abreise nach New York. 1938 fliehen Walter und Tilly Loeb, nachdem sie alles unter Wert verkauft haben, am 27.05. mit dem Dampfer »Orinoco« von Hamburg nach Cuba und von Havanna am 22.07. mit der »Florida« nach Miami, wo sie einen Zug nach New York nehmen. Walter Loeb arbeitet in einer Farbenfabrik. Im Januar 1941 wird er auf der Straße niedergestochen und ist bis 1943 arbeitsunfähig. Er stirbt am 01.06.1970 in New York, Tilly am 29.11.2009.</Text>
            <Heading variant="utitel">23. Juli 1938</Heading>
            <Text variant="solid">Herta Mayer, geboren am 01.05.1910, flieht nach New York. Sie heiratet dort Isaak Kahn aus Könen an der Saarmündung, der 1936 geflohen ist und sich seitdem Joe Issi Kahn nennt. Sie bleiben kinderlos.</Text>
            <Heading variant="utitel">25. November 1938</Heading>
            <Text variant="solid">Jakob und Clementine Mayer müssen ihren Tabakladen und das Haus in der Rathausstraße 4 aufgeben. Sie ziehen vorübergehend zu Gustav Gerson in die Kirchstraße 19 (heute 85), als auch dieses Haus im November 1939 verkauft werden muss, ziehen sie zu Moritz und Jenny Mayer in die Liebfrauenstraße 50.</Text>
            <Heading variant="utitel"> 24. März 1941</Heading>
            <Text variant="solid">
            Jakob und Clementine Mayer gelingt mit der Hilfe des Besitzers der Schönburg, T. J. Oakley Rhinelander die Flucht über Lissabon nach New York
            </Text>
            
            
            </Box>
            <Box pt="10">
                <Flex flexWrap="wrap" columns={["column","row"]} >
                <Box p="1">
                    <StaticImage src="../images/solo/Walter-Meyer-Loeb-Pass.jpg" />                    <Text variant="Bild"> Pass  von Walter Loeb</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/wohnungloeb.jpg" /> 
                    <Text variant="Bild"> Wohnung von Loeb in der Simmernerstraße</Text>
                </Box>
               
                
               
                <SimpleGrid columns={[1,1,1,2]}>
                <Box overflow="hidden"  m="1">
                    <StaticImage src="../images/solo/clementine-mayer.jpg" width={250} height={330} />
                    <Text variant="Bild">Clementine Mayer geb. Frenkel </Text>
                </Box>
                <Box  m="1">
                    <StaticImage src="../images/solo/Jakob-Mayer.jpg"  alt="Jakob Mayer" width={250} height={330}/>
                    <Text variant="Bild">Jakob Mayer</Text>
                </Box>
                <Box  m="1">
                    <StaticImage  src="../images/oberwesel/18.jpg" alt="Mathilde Tilly Loeb" width={250} height={330}/>
                    <Text variant="Bild">Mathilde (Tilly) Loeb</Text>
                </Box>
                <Box  m="1">
                    <StaticImage  src="../images/solo/Loeb-Todesanzeige-1970.jpg" alt="Mathilde Tilly Loeb" />
                    <Text variant="Bild">Mathilde (Tilly) Loeb</Text>
                </Box>
                </SimpleGrid>
                </Flex>
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}